// If you want to override variables do it here
@import "variables";
// Import styles
@import "~@coreui/coreui/scss/coreui";
// If you want to add something do it here
@import "custom";
@import '~@angular/cdk/overlay-prebuilt.css';
.pure-checkbox input[type=checkbox]+label:before {
  border: 1px solid !important;
}

/* Added code for Add Staff,Add Role, Add Vehicle Buttton and search box start*/
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .navbar-expand-lg .navbar-collapse {
    display: block !important;
    justify-content: flex-end !important;
  }
  .form-inline {
    margin-left: 0px !important;
    width: 100% !important;
    display: block !important;
  }
  .form-inline .btn-primary {
    margin: 0px 0px !important;
  }
  #navbarSupportedContent .form-control.ng-untouched.ng-valid.ng-dirty{
    display: inline-block !important;
    margin-top: 10px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: inline-flex !important;
    justify-content: flex-end !important;
  }
  .navbar {
    display: flex !important;
    flex-wrap: nowrap !important;
  }
   .form-inline{
    margin-left: 5px !important;
    display: block !important;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 1199px){
  .navbar-expand-lg .navbar-collapse{
    display: block !important;
  }
    .navbar-expand-lg .form-control{
    display: inline-block !important;
    margin-top: 10px;
  }
    .navbar-expand-lg .form-inline{
    display: inline-block !important;
  }
  .navbar-expand-lg.navbar-light.bg-light{
    display: block !important;
  }
  .input-group .form-control{
    margin-top: 0px !important;
  }
  .form-inline{
    margin-left: 0px !important;
    margin-top: 5px !important;
  }
  .form-inline .btn.btn-primary.my-2.my-sm-0{
    margin-right: 10px !important;
  }
  .form-inline .input-group{
    margin-right: 5px !important;
  }
}
/* Added code for Add Staff,Add Role, Add Vehicle Buttton and search box etc ... end*/

// Added Header part - User/Driver
.app-header .nav-item .nav-link > .img-avatar{
  width: 36px !important;
  height: 36px !important;
  box-shadow: 0px 0px 6px -1px grey !important;
}   

.nav-item-active {
  color: white;
  background-color: #3a4248;
  border-radius: 5px;
  padding: 5px 10px;
}

.nav-item-active:hover {
  color: white;
}

// NG Select Dropdown css
.ng-select .ng-select-container {
  height: calc(1.5em + 0.75rem + 2px) !important;
  min-height: calc(1.5em + 0.75rem + 2px) !important;
}

.ng-select .ng-select-container .ng-value-container {
  border-top-width: 1px !important;
}
.ng-select {
  position: relative;
  display: block;
  box-sizing: border-box;
  padding-bottom: 0px !important;
}
.ng-dropdown-panel.ng-select-bottom {
  margin-top: 17px;
}